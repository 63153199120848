









import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import userManager from "@/services/userManager";

export default Vue.extend({
  data() {
    return {
      self: this,
      userManager,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
        },
        content: {
          name: "Quản lý kho",
          urlEnabled: true,
          search: {},
          displayFields: {
            name: {
              text: "Tên",
              sortable: true,
            },
            description: {
              text: "Mô tả",
            },
            disabled: {
              text: "Trạng thái",
              options: {
                label(value) {
                  return (value && "Khóa") || "Bình thường";
                },
                attrs(value) {
                  return {
                    color: value ? "red" : "green",
                  };
                },
              },
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            action: {},
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "qlk"]) && {
                target: {
                  dialog: {
                    ext: {
                      subTitleMaker: "name",
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: this.context().$route.params.countryId,
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("warehouses", "findAll", options);
              return [items, count];
            },
            async insert(item, dataTable) {
              item.countryId = dataTable.context().$route.params.countryId;
              return await coreApiClient.call("warehouses", "create", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "warehouses",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("warehouses", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: {
              name: {
                attrs: {
                  label: "Tên",
                  required: true,
                },
              },
              description: {
                type: "textLarge",
                attrs: {
                  label: "Mô tả",
                },
              },
              disabled: {
                type: "select",
                attrs: {
                  label: "Trạng thái",
                  items: [
                    { text: "Bình thường", value: false },
                    { text: "Khóa", value: true },
                  ],
                },
              },
            },
            insertForm: userManager.checkRole(["kt", "qlk"]) && {
              content: {
                fieldNames: ["name", "description", "disabled"],
              },
            },
            editForm: userManager.checkRole(["kt", "qlk"]) && {
              content: {
                fieldNames: ["name", "description", "disabled"],
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "name",
            },
          },
        },
      },
    };
  },
});
